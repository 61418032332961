 /* CSS RESET
 ===================================================================*/


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, /*p,*/ blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, /*ol, ul, li,*/
fieldset, form, label, legend,
/*table,*/ caption, tbody, tfoot, thead, tr, th, td {
margin: 0;
padding: 0;
border: 0;
outline: 0;
/*font-size: 100%;*/
/*vertical-align: baseline;*/
/*background: transparent;*/
}
/*body {
line-height: 1;
}*/

/*ol, ul {
list-style: none;
}*/
/*blockquote, q {
quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
content: '';
}*/
/* remember to define focus styles! */
:focus {
outline: 0;
}
/* tables still need 'cellspacing="0"' in the markup */
/*table {
border-collapse: collapse;
border-spacing: 0;
}*/

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	background: none repeat scroll 0 0 transparent;
    border: 0 none;
    font-size: 100%;
    margin: 0;
    outline: 0 none;
    padding: 0;
    vertical-align: baseline;
}
/*ol, ul {
	list-style: none;
}*/
/*blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
ol, ul {
    list-style: none outside none;
	/*margin:0;
	padding:0;*/
}



/*body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td { 
	margin:0;
	padding:0;
}*/
html,body{
	margin:0;
	padding:0;
}
table {
	border-collapse:collapse;
	border-spacing:0;
}
fieldset,img { 
	border:0;
}
input{
	border:1px solid #b0b0b0;
	padding:3px 5px 4px;
	color:#979797;
	/*width:190px;*/
}
address,caption,cite,code,dfn,th,var {
	font-style:normal;
	font-weight:normal;
}
ol,ul {
	list-style:none;
}
caption,th {
	text-align:left;
}
h1,h2,h3,h4,h5,h6 {
	font-size:100%;
	font-weight:normal;
}
q:before,q:after {
	content:'';
}
abbr,acronym { border:0;
}












