/* GLOBAL STYLES
 ===================================================================*/

@import url("reset.css");
@import url("font-awesome.min.css");
@import url("font-oswald.css");
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,400,700,300);

img {
  border: 0 none;
  max-width: 100%;
  width: auto; /*IE8*/
  vertical-align: top;
}
.jcarousel iframe {
  display: block;
}

body {
  font-size: 12px;
  line-height: 18px;
  color: #7e8082;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #fff;
  -webkit-font-smoothing: antialiased !important;
  -webkit-text-size-adjust: 100% !important;
}
#wrap.boxed {
  background: none repeat scroll 0 0 #ffffff;
}
.grey-bg {
  background-color: #efefef;
}
.dark-grey-bg {
  background-color: #565a60;
}
.light-grey-bg {
  background-color: #efefef;
}
.bold {
  font-weight: bold;
}
::selection {
  color: #414146;
  background-color: #ffea00;
}
::-moz-selection {
  color: #414146;
  background-color: #ffea00;
}
.bw-wrapper canvas {
  cursor: pointer;
}

/* alignment and height */
.right-aligned {
  margin-left: auto;
  margin-right: 0;
  display: block;
}

.center-aligned {
  margin: 0 auto;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.content-center {
  display: block;
  margin: auto;
}

/* HEADER
================================================== */
#header {
}
.header-logo-container {
  float: left;
}
.header-container-right {
  float: right;
}
.social-links-container {
  float: right;
}
.header-rr-container {
  margin-bottom: 5px;
  margin-top: 22px;
}
.header-position {
  position: absolute;
  z-index: 99999;
}

/******SOCIAL LINKS******/
ul.social-links {
  margin: 0;
  padding: 0;
}
ul.social-links li {
  list-style: none outside none;
  float: left;
}
.facebook-link,
.skype-link,
.twitter-link,
.flickr-link,
.vimeo-link,
.dribbble-link,
.linkedin-link,
.rss-link,
.googleplus-link,
.pintrest-link {
  font-size: 0px;
  height: 56px;
  width: 56px;
  display: block;
  background-color: #ffea00;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.facebook-link:hover,
.skype-link:hover,
.twitter-link:hover,
.flickr-link:hover,
.vimeo-link:hover,
.dribbble-link:hover,
.linkedin-link:hover,
.rss-link:hover,
.googleplus-link:hover,
.pintrest-link:hover {
  background-color: #2f343a;
}
.facebook-link {
  background-image: url(../images/social/facebook.png);
}
.skype-link {
  background-image: url(../images/social/skype.png);
}
.twitter-link {
  background-image: url(../images/social/twitter.png);
}
.flickr-link {
  background-image: url(../images/social/flickr.png);
}
.vimeo-link {
  background-image: url(../images/social/vimeo.png);
}
.dribbble-link {
  background-image: url(../images/social/dribbble.png);
}
.linkedin-link {
  background-image: url(../images/social/linkedin.png);
}
.rss-link {
  background-image: url(../images/social/rss.png);
}
.googleplus-link {
  background-image: url(../images/social/googleplus.png);
}
.pintrest-link {
  background-image: url(../images/social/pintrest.png);
}
.facebook-link:hover {
  background-image: url(../images/social/facebook-hover.png);
}
.skype-link:hover {
  background-image: url(../images/social/skype-hover.png);
}
.twitter-link:hover {
  background-image: url(../images/social/twitter-hover.png);
}
.flickr-link:hover {
  background-image: url(../images/social/flickr-hover.png);
}
.vimeo-link:hover {
  background-image: url(../images/social/vimeo-hover.png);
}
.dribbble-link:hover {
  background-image: url(../images/social/dribbble-hover.png);
}
.linkedin-link:hover {
  background-image: url(../images/social/linkedin-hover.png);
}
.rss-link:hover {
  background-image: url(../images/social/rss-hover.png);
}
.googleplus-link:hover {
  background-image: url(../images/social/googleplus-hover.png);
}
.pintrest-link:hover {
  background-image: url(../images/social/pintrest-hover.png);
}

/* SEARCH
================================================== */
.search-title {
  float: left;
  background-color: #2f343a;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  color: #fff;
  padding: 10px 15px;
}
.search-container {
  float: right;
}
.search-text {
  border: none !important;
  color: #c1c1c1 !important;
  display: block !important;
  float: left !important;
  margin: 0 !important;
  font-style: normal !important;
  padding: 9px 10px !important;
  background-attachment: scroll;
  background-color: #565a60;
  background-image: none;
  background-repeat: repeat;
  background-position: 0 0;
  width: 100px;
  height: 56px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
}
.search-submit {
  margin: 0;
  padding: 0;
  width: 38px; /*32*/
  height: 74px;
  border: medium none;
  background-image: url(../images/search-submit.png);
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  background-attachment: scroll;
  background-color: #565a60;
}
.search-submit:hover {
  background-color: #ffea00;
  cursor: pointer;
  background-image: url(../images/search-submit-hover.png);
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.search-form {
  height: 38px;
  margin-left: 15px;
}
.header-container {
  /* background-color: #ffffff; */
  /* background-color: #414146;	 */
  background-color: #000000;
}

/* LOGO
================================================== */
.logo-footer-container {
  /* background-color:#565a60; */
  background-color: #000000;
}
.logo-footer-container a {
  margin: 0;
  padding: 55px 30px;
}
.logo {
  width: 100px;
  height: 20px;
  margin-left: 27px;
  margin-top: 27px;
  margin-bottom: 25px;
  display: block;
}

/* MAIN MENU
================================================== */
.header-rl-container {
  width: 771px;
}
#main-nav {
  float: left;
}
nav#main-nav select {
  display: none; /* for mobile */
}
#main-nav ul {
  margin: 0;
  padding: 0;
}
#main-nav li {
  float: left;
  list-style: none outside none;
  margin: 0;
}
#main-nav ul li li:first-child {
  border-top: none;
}
#main-nav .current > a {
  color: #414146;
  background-color: #ffea00;
}
#main-nav .current li.current a,
#main-nav .current li.current li.current a {
  color: #fff !important;
  margin-top: 0;
  border-top: none;
  padding-top: 12px;
  background-color: #8a8d91 !important;
}
#main-nav a:hover {
  text-shadow: none;
  color: #414146;
  background-color: #ffea00;
}
#main-nav a {
  color: #fff;
  display: block;
  font-size: 14px;
  position: relative;
  text-decoration: none;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  padding-top: 30px;
  padding-bottom: 0px;
}
.sf-menu li > a {
  padding-bottom: 30px !important;
  text-transform: uppercase;
  padding-right: 17px;
  padding-left: 17px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
}
#main-nav ul.sf-menu li ul {
  /* border-right: 1px solid #fff;
border-bottom: 1px solid #fff;*/
  /*box-shadow: 0px 2px 0px 5px rgba(0,0,0, 0.3);*/
  /*border-right:3px solid #414146;
border-left:3px solid #414146;
border-bottom:3px solid #414146;
box-sizing: border-box !important; */
}

/*** ESSENTIAL STYLES ***/
.sf-menu,
.sf-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sf-menu {
  line-height: 1;
}
.sf-menu ul {
  position: absolute;
  top: -999em;
  margin-top: 0px !important;
}
#main-nav ul.sf-menu li:first-child ul {
  margin-left: 0px !important;
}
.sf-menu ul ul {
  margin: 0 !important;
}
.sf-menu ul li {
  width: 150px;
}
.sf-menu li:hover {
  visibility: inherit; /* fixes IE7 'sticky bug' */
}
.sf-menu li {
  float: left;
  position: relative;
}
.sf-menu a {
  display: block;
  position: relative;
}
.sf-menu li:hover ul,
.sf-menu li.sfHover ul {
  left: 0px;
  top: /*5.5em*/ 74px; /* match top ul list item height */
  z-index: 99;
  text-shadow: none;
}
ul.sf-menu li:hover li ul,
ul.sf-menu li.sfHover li ul {
  top: -999em;
}
ul.sf-menu li li:hover ul,
ul.sf-menu li li.sfHover ul {
  left: 150px !important; /* match ul width */
  top: 0;
}
ul.sf-menu li li:hover li ul,
ul.sf-menu li li.sfHover li ul {
  top: -999em;
}
ul.sf-menu li li li:hover ul,
ul.sf-menu li li li.sfHover ul {
  left: 100em; /* match ul width */
  top: 0;
}
.sf-menu li li {
  margin: 0 !important;
  background-color: #535961;
}
#main-nav .current li.current li a {
  background-color: #535961;
  color: #fff;
}
.sf-menu li li a {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  text-transform: none !important;
  text-shadow: none !important;
  font-size: 12px !important;
  padding-left: 15px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  color: #fff !important; /*14*/
}
.sf-menu li li a:hover {
  color: #414146 !important;
  background: #ffea00 !important;
}

/* 3 BLOCKS (ver. 1)
================================================= */
.block-0-content-container {
  height: 252px;
  background-color: #fff;
  position: relative;

  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.block-1-content-container {
  background-color: #ffea00;

  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.block-2-content-container {
  background-color: #edda06;

  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.block-3-content-container {
  background-color: #decb01;

  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.block-1-content-container:hover,
.block-2-content-container:hover,
.block-3-content-container:hover {
  background-color: #fff;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.title-block-text {
  border-bottom: 5px solid #efefef;
  color: #414146;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 36px;
  padding-bottom: 15px;
}
.block-text {
  margin: 25px;
  margin-top: 0;
  padding-bottom: 15px;
  padding-top: 25px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 36px;
  color: #414146;
  border-bottom: 5px solid #efefef;
}
.block-r-m-container {
  position: absolute;
  bottom: 0;
  right: 0;
}
.ca-icon {
  text-align: center;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}
.ca-icon img {
  padding-bottom: 25px;
  padding-top: 50px;
  height: 70px;
  width: 90px;

  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}
.ca-icon svg {
  padding-bottom: 25px;
  padding-top: 50px;
  height: 70px;
  width: 90px;

  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}
.ca-main {
  color: #414146;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */


  text-align: center;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}
.ca-sub {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 17px;
  margin-bottom: 0;
  color: #6f6f73;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}
.ca-menu:hover .ca-icon img {
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);

  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}
.ca-menu:hover .ca-icon svg {
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);

  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}
.ca-menu:hover .ca-sub {
  -webkit-animation: moveFromBottom 500ms ease;
  -moz-animation: moveFromBottom 500ms ease;
  -ms-animation: moveFromBottom 500ms ease;
}
@-webkit-keyframes smallToBig {
  from {
    -webkit-transform: scale(0.1);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes smallToBig {
  from {
    -moz-transform: scale(0.1);
  }
  to {
    -moz-transform: scale(1);
  }
}
@-ms-keyframes smallToBig {
  from {
    -ms-transform: scale(0.1);
  }
  to {
    -ms-transform: scale(1);
  }
}

@-webkit-keyframes moveFromBottom {
  from {
    -webkit-transform: translateY(50%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0%);
    opacity: 1;
  }
}
@-moz-keyframes moveFromBottom {
  from {
    -moz-transform: translateY(50%);
    opacity: 0;
  }
  to {
    -moz-transform: translateY(0%);
    opacity: 1;
  }
}
@-ms-keyframes moveFromBottom {
  from {
    -ms-transform: translateY(50%);
    opacity: 0;
  }
  to {
    -ms-transform: translateY(0%);
    opacity: 1;
  }
}

/* 3 BLOCKS (ver. 1) - black
================================================== */
.block-black-text {
  background-color: #ffea00;
}
.block-black {
  background-color: #414146 !important;
}
.block-black-text .block-text {
  border-bottom: 5px solid #414146;
}
.block-black .ca-icon {
  background-color: #565a60;
}
.block-black .ca-main {
  color: #fff;
}
.block-black .ca-sub {
  color: #b0afaf;
}
.block-black .ca-icon img {
  padding-bottom: 37px;
  padding-top: 38px;
}
.block-black.block-1-content-container:hover,
.block-black.block-2-content-container:hover,
.block-black.block-3-content-container:hover {
  background-color: #565a60;
}

/* 3 BLOCKS (3 ver)
================================================== */
.content-container-black {
  background-color: #414146;
}

.content-container-black .ca-icon {
  background-color: #fff;
  padding-bottom: 25px;
}
.content-container-black .ca-main {
  color: #fff;
}
.content-container-black .ca-sub {
  color: #b0afaf;
}

/* 3 BLOCKS (2 ver)
================================================== */
.block-testimonials .carousel-navi {
  bottom: 0px;
}
.block2-a {
  display: block;
}
.block2-a:hover .box2-img-container {
  background-color: #fff;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);

  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.box2-img-container {
  float: left;
  background-color: #ffea00;

  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.box2-img-container img {
  height: 30px;
  width: 40px;
  padding-top: 33px;
  padding-right: 27px;
  padding-bottom: 33px;
  padding-left: 27px;
}
.box2-text-container h3 {
  color: #535961;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 22px;
  padding: 37px 20px 0px 115px;
}
.box2-text-container h5 {
  color: #7e8082;
  font-size: 12px;
  line-height: 18px;
}
.block2-a:hover .box2-img-container-inv {
  background-color: #ffea00;

  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -ms-transition: all 0.3s !important;
}
.box2-img-container-inv {
  background-color: #535961;

  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -ms-transition: all 0.3s !important;
}

/* 3 BLOCKS (3 ver)
================================================== */
.block3-a {
  display: block;
}
.block3-a:hover .box3-img-container {
  background-color: #fff;
  -webkit-transform: scale(1.45);
  -moz-transform: scale(1.45);
  -o-transform: scale(1.45);
  -ms-transform: scale(1.45);
  transform: scale(1.45);

  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.box3-img-container {
  float: left;
  background-color: #ffea00;

  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.box3-img-container img {
  height: 30px;
  width: 40px;
  padding-top: 33px;
  padding-right: 27px;
  padding-bottom: 33px;
  padding-left: 27px;
}

.box3-text-container h3 {
  color: #535961;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 22px;
  padding: 37px 20px 0px 115px;
}
.box3-text-container h5 {
  color: #7e8082;
  font-size: 12px;
  line-height: 18px;
}
.block3-a:hover .box3-img-container-inv {
  background-color: #ffea00;

  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -ms-transition: all 0.3s !important;
}
.box3-img-container-inv {
  background-color: #535961;

  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -ms-transition: all 0.3s !important;
}
.box3-description-container {
  border-bottom: 3px solid #ffea00;
}
.box3-description {
  margin: 18px 20px 18px 20px;
  color: #808080;
}

/* 3 BLOCKS (5 ver)
================================================== */
.block5-a {
  display: block;
}
.block5-a:hover .box5-img-container {
  background-color: #535961;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.box5-img-container,
.box5-img-container-inv {
  float: left;
  background-color: #ffea00;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.box5-img-container img,
.box5-img-container-inv img {
  height: 40px;
  padding-top: 33px;
  padding-right: 27px;
  padding-bottom: 33px;
  padding-left: 27px;
}
.box5-text-container {
  padding: 20px 20px 20px 128px;
}
.box5-text-container h3 {
  color: #535961;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 22px;
}
.box5-text-container h5 {
  color: #7e8082;
  font-size: 12px;
  line-height: 18px;
}
.block5-a:hover .box5-img-container-inv {
  background-color: #ffea00;

  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -ms-transition: all 0.3s !important;
}
.box5-img-container-inv {
  background-color: #535961;

  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -ms-transition: all 0.3s !important;
}

/* BUY NOW
================================================== */
.buy-img-container {
  float: left;
  background-color: #ffea00;
}
.buy-img-container img {
  height: 29px;

  padding: 30px;
}
.buy-text h2 {
  color: #fff;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 22px;
}
.buy-now-slogan {
  color: #b8bcc1;
}
.buy-text-container,
.buy-img-container {
  float: left;
}
.button-buy-container {
  float: right;
}
.button-buy-container a {
  display: block;
  background-color: #ffea00;
  color: #414146;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 29px;
  padding: 30px;
}
.button-buy-container a:hover {
  background-color: #2f343a;
  color: #fff;
}
.buy-text-container {
  padding: 21px 25px 18px 25px;
}

/* NEWSLETTER
================================================== */
.nl-img-container {
  float: left;
  background-color: #ffea00;
}
.nl-img-container img {
  height: 29px;
  padding: 26px 25px 25px;
}
.nl-button {
  margin: 0 !important;
  padding: 0;
  display: block;
  float: right;
  background-color: #ffea00;
  color: #414146;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 30px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 25px;
  padding-top: 23px;
  height: 80px;
  border: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.nl-button:hover {
  background-color: #2f343a;
  color: #fff;
}
.nl-form-part-container {
  float: left;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  border-left: none;
}
.nl-text {
  float: left;
  padding-top: 32px;
  padding-left: 20px;
  color: #b8bcc1;
}
.nl-text-container .caption {
  float: left;
  padding-top: 31px !important;
  padding-left: 25px !important;
}
.nl-form-container {
  float: right;
}
.newsletterform input {
  width: 180px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.caption {
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  color: #ffffff;
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 7px;
  padding-top: 6px;
  font-weight: lighter;
}
input,
textarea {
  color: #fff;
  background-color: #b8bcc1;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  margin: 0;
  outline: medium none;
  border: none;
  padding: 10px 10px 10px 10px;
  font-size: 13px;
}
.nl-form-container input {
  box-shadow: none;
  padding: 7px 9px;
  margin-right: 22px;
  height: 80px;
  margin-right: 0;
  margin-top: 0;
  padding-left: 25px;

  color: #fff;
  background-color: #b8bcc1;
}

/* LATEST WORK
================================================== */

.caption-block {
  float: left;
  display: block;
  width: 97%;
  position: absolute;
  bottom: 20px;
  right: 10px;
}
.p-10 {
  padding: 10px;
}
.caption-main-container {
  padding: 0px;
}
.jcarousel-scroll {
  float: right;
}
.jcarousel-next {
  width: 38px;
  height: 38px;
  display: block;
  float: right;

  background-color: #b8bcc1;
  background-image: url(../images/next-small.png);
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
.jcarousel-next:hover {
  background-image: url(../images/next-small-black.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.jcarousel-prev:hover {
  background-image: url(../images/prev-small-black.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.jcarousel-next:hover,
.jcarousel-prev:hover {
  background-color: #ffea00;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
.jcarousel-prev {
  width: 38px;
  height: 38px;
  display: block;
  float: left;

  background-color: #b8bcc1;
  background-image: url(../images/prev-small.png);
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
/*****************************************/
.content-container-white {
  background-color: #fff;
}
content-container-grey {
}
.content-container-white-no-shadow {
  background-color: #fff;
}
.view {
  overflow: hidden;
  position: relative;
}
.hover-item:hover .mask {
  opacity: 0.5;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
}
.view-first .mask {
  background-color: #ffea00;
  height: 100%;
  opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  transition: all 0.4s ease-in-out 0s;
  width: 100%;
}

/*************************************/
.lw-text-container {
  padding: 10px;
}
.lw-text-container p {
  padding: 0px;
  margin: 0px;
}
.lw-a-container {
  text-align: right;
}
.lw-item-container {
  background-color: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
}
.lw-item-caption-container {
  padding-top: 15px;
  padding-bottom: 12px;
  margin-bottom: 1px;
}
.item-caption {
  color: #9fa2a6;
  margin-top: 8px;
}
.item-title-main-container {
  border-bottom: 2px solid #e6e7e6;
  padding: 0;
}
.item-title-text-container {
  border-bottom: 2px solid #9e9e9e;
  margin-bottom: -2px;
  padding-bottom: 13px;
  display: inline-block;
}
.lw-item-caption-container a {
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #414146;
}
.lw-item-text-container {
  text-align: center;
}
.lw-item-text-container p {
  margin: 8px 15px;
}
.jcarousel {
  position: relative;
  overflow: hidden;
}

.jcarousel ul {
  width: 20000em;
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
}

/* LATEST POSTS
================================================== */
.lp-item-caption-container {
  padding-top: 13px;
}
.lp-item-caption-container a {
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 16px;
}
.lp-item-container-border {
  margin-top: 7px;
}
.lp-item-container-border-exist {
  margin-top: 7px;
  border-bottom: 2px solid #e6e7e6;
}
.lp-item-info-container {
  border-bottom: 2px solid #9e9e9e;
  display: inline-block;
  margin-bottom: -2px;
  padding-bottom: 7px;
  font-size: 11px;
}

.lp-item-container-border {
  font-size: 11px;
}
.lp-item-text-container {
  border-bottom: 2px dotted #e6e7e6;
}
.lp-r-m-container {
  margin-top: -2px;
  right: 0;
}
.lp-r-m-container .r-m-plus-small {
  float: right;
}

/* OUR CLIENTS
================================================== */
.bw-wrapper {
  position: relative;
  display: block;
}
ul.our-clients-container {
  padding: 0;
  margin: 0;
}
.our-clients-container li {
  display: block;
  float: left;
  overflow: hidden;
  position: relative;
  padding-top: 15px;
  padding-right: 0px;
  padding-bottom: 15px;
  padding-left: 0px;
}

/* CAPTION
================================================== */
.caption-text-container {
  font-size: 18px;
  color: #414146;
  font-weight: lighter;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  background-color: #fff;
  padding-right: 20px;
  display: inline-block;
}
.caption-text-container-test-block {
  font-size: 18px;
  color: #fff;
  font-weight: lighter;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;

  padding-left: 20px;
  padding-top: 20px;
}
.caption-bg {
  width: 100%;
  height: 5px;
  border-bottom: 5px solid #e6e7e6;
}
.caption-container {
  position: relative;
}
.carousel-navi {
  position: absolute;
  bottom: -33px;
  right: 0;
}

/***************************************************/

.caption-container-main {
  height: 30px;
  position: relative;
  margin-top: 15px;
}

.caption-text-container {
  float: left;
  position: absolute;
  bottom: 0;
  z-index: 9;
}
.caption-bg {
  bottom: 6px;
  height: 5px;
  position: absolute;
  width: 100%;
}
.carousel-navi.caption-control {
  bottom: -10px;
  position: absolute;
  right: 0;
}

/* TABS
================================================== */
.tabs-nav {
  list-style: none outside none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
.tabs-nav li {
  float: left;
  line-height: 32px;
  margin: 0;
  padding: 0;
  overflow: hidden;

  position: relative;
}
.tabs-nav li a {
  color: #fff;
  display: block;
  outline: medium none;
  text-decoration: none;
  background-color: #b0b3b6;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  padding-top: 4px;
  padding-right: 16px;
  padding-bottom: 3px;
  padding-left: 16px;
}
.tabs-nav li a:hover,
.tabs-nav li.active a {
  background-color: #ccced0;
}
.tabs-nav li.active a {
  color: #fff;
  background-color: #565a60;
}
.tabs-container {
  margin: -1px 0;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}
.tab-content {
  padding-top: 0px;
  padding-bottom: 0px;
}
.tab-content p {
  padding-right: 15px;
}
.tab-post-container {
  margin: 0;
  padding: 0;
}
.tab-post-container li {
  background-color: #565a60;
  color: #fff;
}
.tab-post-container.text p {
  margin: 0;
  padding-bottom: 17px;
  padding-top: 17px;
  padding-left: 20px;
  padding-right: 20px;
}
.tab-post-container li {
  border-top: 1px solid #d1d1d1;
}
.tab-post-container li:first-child {
  border-top: none;
}
.lp-title-cont {
  padding-left: 20px;
}

/* TESTIMONIALS
================================================== */

.content-container-grey {
  background-color: #565a60;
}
.block-3-col .quote-content {
  border: 1px solid #565a60;
  min-height: 153px;
}
.quote-content {
  border: 1px solid #565a60;
}
.quote-content:before,
.quote-content:after {
  content: "";
  position: absolute;
}
.t1 {
  background: #fff;
}
.testimonials-jc li {
  position: relative;
}
.quote-content {
  background-image: url(../images/quote.png);
  background-repeat: no-repeat;
  background-position: 15px 15px;
}
.quote-content p {
  margin: 20px 20px 20px 56px;
  font-style: italic;
  color: #fff;
}
.quote-author {
  background-color: #fff;
  display: inline-block;
  margin-bottom: 1px;
  margin-top: -1px;
  float: right;
  font-style: italic;
  padding-top: 0px;
  padding-right: 20px;
  padding-bottom: 15px;
  padding-left: 20px;
}
.quote-author-description {
  color: #b6babf;
}
.author-testimonial {
  font-size: 12px;
  color: #fff;
  font-weight: bold;
}
p.t-a-container {
  margin-bottom: 13px;
  margin-top: 14px;
}

/* PAGE TITLE
================================================== */
.page-title-container {
  background-color: #ffea00;
}
.page-title {
  float: left;
  color: #414146;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  /* font-size: 22px; */
  line-height: 22px;
  margin-bottom: 0;
  padding: 20px;
}

/*****FOR WEB ******/
@media only screen and (min-width: 768px) {
  .page-title {
    font-size: 22px;
  }
}

/*****FOR MOBILE ******/
@media only screen and (max-width: 767px) {
  .page-title {
    font-size: 16px;
  }
}

.page-title .sub-title {
  color: rgba(0, 0, 0, 0.454);
}
.page-title a {
  color: #2f343a;
}
.page-title a:hover {
  color: #fff;
}

/* 404
================================================== */
.error404-text {
  text-align: left;
  color: #7e8082;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 62px;
  line-height: 72px;
  margin-left: 50px;
  margin-top: 25px;
}
.error404-numb {
  color: #c2c7cd;
  text-align: right;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 230px;
  line-height: 230px;
  margin-bottom: 0;
  margin-right: 50px;
  margin-top: 15px;
}
.error404-main-text h2 {
  text-align: center;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 30px;
  padding-bottom: 15px;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: uppercase;
  color: #7e8082;
  /* color: #000000; */
}
.error404-main-text h2:hover {
  color: #e9d600;
}
.error404-main-text {
  margin-bottom: 40px;
  margin-top: 40px;
  text-align: center;
}
.error404-container {
  margin-top: 80px;
}

/* CONTACT
================================================== */
.google-map {
  padding-bottom: 560px;
}
.google-map-container img {
  max-width: none !important;
}
.contant-container-caption {
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  margin: 0px;
  font-size: 16px;
  color: #535961;
  padding-top: 18px;
  padding-right: 18px;
  padding-bottom: 18px;
  padding-left: 18px;
}
.padding-cont-15 {
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.padding-all-15 {
  padding: 15px;
}
.padding-l-t-15 {
  padding: 15px 0 0 15px;
}
.padding-l-r-15 {
  padding: 0 15px 0 15px;
}
.padding-cont-15 p {
  margin-bottom: 0;
}
.title-block,
.title-widget {
  color: #414146;
  font-size: 18px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  margin-bottom: 0;
  padding: 4px 13px 5px;
}
.title-widget {
  line-height: 18px;
  margin: 12px;
  padding: 0;
}
ul.contact-info-page {
  margin: 0;
  padding: 0;
}
ul.contact-info-page li {
  border-top: 1px solid #d1d1d1;
}
ul.contact-info-page li:first-child {
  border-top: none;
}
.contact-info-page .icon_loc {
  background: url(../images/icon_footer_loc.png) no-repeat scroll 15px 13px transparent;
  margin-bottom: 10px;
  padding: 10px 15px 0 45px;
  background-color: #535961;
}
.contact-info-page .icon_phone {
  background: url(../images/icon_footer_phone.png) no-repeat scroll 15px 14px transparent;
  margin-bottom: 10px;
  padding: 10px 15px 0 45px;
  background-color: #535961;
}
.contact-info-page .icon_mail {
  background: url(../images/icon_footer_mail.png) no-repeat scroll 15px 14px transparent;
  line-height: 24px;
  margin-bottom: 10px;
  padding: 10px 15px 0 45px;
  background-color: #535961;
}
.contact-loc:before,
.contact-loc:after {
  content: "";
  position: absolute;
}
.contact-loc:after {
  top: 0px;
  left: -56px;
  width: 56px;
  height: 56px;
  background-image: url(../images/icon_footer_loc-black.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ffea00;
}
.contact-phone:before,
.contact-phone:after {
  content: "";
  position: absolute;
}
.contact-phone:after {
  top: 0px;
  left: -56px;
  width: 56px;
  height: 56px;
  background-image: url(../images/icon_footer_phone-black.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ffea00;
}
.contact-mail:before,
.contact-mail:after {
  content: "";
  position: absolute;
}
.contact-mail:after {
  top: 0px;
  left: -56px;
  width: 56px;
  height: 56px;
  background-image: url(../images/icon_footer_mail-black.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ffea00;
}
.contact-mail a {
  color: #7e8082;
}
.contact-mail a:hover {
  color: #ffea00;
}
ul.contact-list {
  margin: 0;
  padding: 0;
}
ul.contact-list li {
  display: block;
  margin-left: 54px;
  min-height: 34px;
  padding-top: 9px;
  padding-right: 15px;
  padding-bottom: 11px;
  padding-left: 15px;
  position: relative;
}

/* CONTACT FORM
================================================== */

#contact-form label,
#comment-form label {
  visibility: hidden;
  display: none;
}
.field-1-1 textarea {
  width: 700px;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #contact-form .field-1-1 textarea {
    margin-bottom: -4px;
  }
  .leave-comment-container .field-1-1-comment textarea {
    margin-bottom: -4px;
  }
}

.field-1-3 input {
  width: 220px;
  margin-right: 20px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.field-1-3:nth-of-type(3n) input {
  margin-right: 0;
}

/* ALERT BOXES
================================================== */
.iconed-box.error:before {
  background-position: -36px center;
}
.iconed-box.success:before {
  background-position: 0 center;
}
.iconed-box.info:before {
  background-position: -72px center;
}
.iconed-box.notice:before {
  background-position: -108px center;
}
.iconed-box:before {
  height: 100%;
  left: -43px;
  max-height: 80px;
  top: 0;
  width: 36px;
  background-attachment: scroll;
  background-color: transparent;
  background-image: url(../images/box-icons.png);
  background-repeat: no-repeat;
  background-position: 0 0;
}
.iconed-box:before {
  content: "";
  position: absolute;
}
.styled-box.success {
  background: none repeat scroll 0 0 #edffd7;
  border-color: #80c080;
  color: #32a432;
}
.styled-box.error {
  background: none repeat scroll 0 0 #fdebeb;
  border-color: #ffa7a1;
  color: #e17585;
}
.styled-box.info {
  background: none repeat scroll 0 0 #e2f1ff;
  border-color: #73bfe4;
  color: #4ac2f3;
}
.styled-box.notice {
  background: none repeat scroll 0 0 #ffffee;
  border-color: #fbc778;
  color: #ff9900;
}
.styled-box {
  background: none repeat scroll 0 0 #ffffff;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 50px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: #d6d6d6;
  border-right-color: #d6d6d6;
  border-bottom-color: #d6d6d6;
  border-left-color: #d6d6d6;
}
.styled-box {
  margin-bottom: 15px;
  position: relative;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 15px;
  padding-left: 20px;
}
.demo .styled-box {
  padding-top: 20px;
  padding-bottom: 20px;
}

#contact-form .iconed-box {
  padding-left: 20px;
  width: 220px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

/* BLOG
================================================== */
.blog-info-container {
  padding-top: 3px;
}
.blog-info-container ul {
  margin: 0;
  padding-bottom: 0;
  padding-left: 0px;
  padding-right: 10px;
}
.blog-info-container ul li {
  font-size: 13px;
  line-height: 14px;
  margin: 0;
}
/* .blog-info-container ul li.date {
  display: block;
  float: left;
  padding: 10px 20px;
  background-attachment: scroll;
  background-color: transparent;
  background-image: url(../images/clock-icon.png);
  background-repeat: no-repeat;
  background-position: left center;
} */
.blog-info-container ul li.author {
  display: block;
  float: left;
  padding: 10px 20px;
  background-attachment: scroll;
  background-color: transparent;
  background-image: url(../images/author-icon.png);
  background-repeat: no-repeat;
  background-position: left center;
}
.blog-info-container ul li.view {
  display: block;
  float: left;
  padding: 10px 20px;
  background-attachment: scroll;
  background-color: transparent;
  background-image: url(../images/view-icon.png);
  background-repeat: no-repeat;
  background-position: left center;
}
.blog-info-container ul li.comment {
  display: block;
  float: left;
  padding: 10px 20px;
  background-attachment: scroll;
  background-color: transparent;
  background-image: url(../images/comment-icon.png);
  background-repeat: no-repeat;
  background-position: left center;
}
.blog-info-container ul li.tag {
  display: block;
  float: left;
  padding: 10px 20px;
  background-attachment: scroll;
  background-color: transparent;
  background-image: url(../images/tag-icon.png);
  background-repeat: no-repeat;
  background-position: left center;
}
.blog-info-container ul li.post {
  display: block;
  float: left;
  padding: 10px 28px;
  background-attachment: scroll;
  background-color: transparent;
  background-image: url(../images/icon-standart-post-black.png);
  background-repeat: no-repeat;
  background-position: left center;
}
.blog-text-container {
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}
.blog-item-date-cont {
  display: inline-block;
  background-color: #ffea00;
  color: #414146;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  padding-top: 11px;
  padding-right: 20px;
  padding-bottom: 8px;
  padding-left: 20px;
  width: 65px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.portfolio-item .blog-item-title-author-cont {
  position: absolute;
  bottom: 0px;
  left: 88px;
}

.blog-item-title {
  font-size: 18px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  color: #535961;
}
.blog-item-title:hover {
  color: #ffea00;
}

.blog-item-title-author-cont {
  position: absolute;
  bottom: 0px;
  left: 103px;
}
.blog-item-title-author-cont a {
  color: #fff;
  padding-left: 25px;
  padding-right: 25px;

  padding-bottom: 19px;
  padding-top: 16px;
  background-color: #535961;
}
.blog-item-title-author-cont a:hover {
  color: #fff;
  background-color: #ffea00;
}

.blog-item-title-author {
  color: #fff;
  font-size: 24px;

  padding: 0;
  margin: 0;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
}
.blog-item-date {
  font-size: 26px;
  line-height: 30px;
  text-align: center;
}
.blog-item-mounth {
  margin-top: 3px;
  margin-bottom: 3px;
  text-align: center;
}
.blog-item-mounth,
.blog-item-year {
  font-size: 14px;
}
.blog-item-caption-container .lp-item-container-border {
  margin-top: 0;
}
.blog-item-category-img {
  background-color: #414146;
  padding: 25px 23px;
}
.blog-item-text-container {
  padding-top: 80px;
}

.blog-item-content > p {
  font-size: 14px;
  line-height: 30px;
}
/******PAGINATION******/
.pagination-1 {
  margin: 0;
  padding: 0;
}
.pagination-1 li {
  float: left;
  margin: 0;
  padding: 0;
}
.pagination-1 li a {
  display: block;
  padding: 12px 17px;
  background-color: rgb(0, 0, 0);
  color: #fff;
}
.pagination-1 a {
  color: #414146;
}
.pagination-1 a:hover {
  background-color: #ffea00;
  color: #414146;
}
.pagination-1 a.pag-current {
  background: #ffea00;
  color: #414146;
}
.pagination-1 .pag-next {
  padding-left: 16px;
  padding-right: 17px;
  padding-bottom: 12px !important;
  padding-top: 13px !important;
  background-image: url(../images/next-small.png);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 17px;
  width: 7px;
  background-color: #4a4a4a;
}
.pagination-1 .pag-prev {
  padding-left: 17px;
  padding-right: 16px;
  padding-bottom: 12px !important;
  padding-top: 13px !important;
  background-image: url(../images/prev-small.png);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 17px;
  width: 7px;
  background-color: #4a4a4a;
}
.pagination-1 .pag-prev:hover {
  background-image: url(../images/prev-small-black.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ffea00;
}
.pagination-1 .pag-next:hover {
  background-image: url(../images/next-small-black.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ffea00;
}
.blog-item-caption-container {
  position: relative;
}
.blog-item-date-inf-container {
  position: absolute;
  left: 0;
}
.blog-item-title-author {
  padding-top: 13px;
  position: absolute;
  left: 80px;
}

.blog-item-caption-container a {
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 18px;
}
/*****SIDEBAR******/
.blog-categories {
  margin: 0;
  padding: 0;
}
.blog-categories li.active a {
  color: #535961;
}
.blog-categories li a {
  color: #7e8082;
  line-height: 38px;
  display: block;
}
.blog-categories li a:hover {
  color: #ffea00;
}
.blog-cat-icon {
  display: block;
  float: left;
  margin-right: 10px;
  height: 38px;
  width: 38px;
  background-color: #7c8187;
  background-image: url(../images/next-small.png);
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
.blog-categories li a:hover .blog-cat-icon {
  background-color: #ffea00;
  background-image: url(../images/next-small-black.png);
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
.blog-categories li.active .blog-cat-icon {
  background-color: #ffea00;
  background-image: url(../images/next-small-black.png);
  background-repeat: no-repeat;
  background-position: center center;
}
/******TABS BLOG*****/
.latest-post-container {
  margin: 0;
  padding: 20px 0 20px 0;
  background-color: #565a60;
}
.latest-post-sidebar img {
  background: none repeat scroll 0 0 #ffffff;
  float: left;
  height: 86px;
  width: 86px;
  margin: 0;
  transition: all 0.3s linear 0s;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
}
.latest-post-sidebar img:hover {
  border-color: #ffea00;
  background: none repeat scroll 0 0 #ffea00;
}
.latest-post-sidebar p.latest-post-sidebar-title {
  line-height: 15px;
  padding-top: 5px;
  font-size: 14px;
  margin-bottom: 0;
}
.latest-post-sidebar p.latest-post-sidebar-date {
  color: #7e8082;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 3px;
}
.latest-post-sidebar p.latest-post-sidebar-comm {
  color: #b7b7b7;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 11px;
}

/* PROGRESS BAR
====================================*/
.skill-bar {
  height: 36px;
  position: relative;
  background: #f6f6f6;
  margin: 0 0 5px 0;
}

.skill-bar-content[data-percentage] {
  text-indent: -9999px;
}

.skill-bar-content {
  background: #ffea00;
  height: 36px;
  width: 0%;
}

.skill-bar .skill-title {
  color: #414146;
  top: 10px;
  left: 15px;
  position: absolute;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.skill-bar .percentage {
  color: #666;
  position: absolute;
  top: 9px;
  left: 90%;
  background-color: #0c0;
}
/* ACCORDION
================================================== */
h3.ui-accordion-header {
  margin: 0;
  cursor: pointer;
  color: #fff;
}
h3.ui-accordion-header:first-child {
  margin: 0;
}
.ui-accordion-header {
  font-size: 15px;
  line-height: 40px;
  margin: 0;
  background-color: #565a60;
  display: flex;
}
.ui-accordion-content p {
  margin: 0;
  padding-top: 0px;
  padding-bottom: 12px;
  color: #fff;
}
.ui-accordion-content p:last-child {
  padding-bottom: 0px;
}
.ui-accordion-content {
  margin-left: 50px;
  background-color: #b8bcc1;
  display: none;
  margin-left: 39px;
  padding: 15px;
}
.ui-accordion-header a {
  color: #fff;
}
.ui-accordion-header:hover a {
  color: #ffea00;
}
.ui-accordion-header-active a {
  color: #ffea00;
}
.ui-accordion-header span {
  background-color: #7c8187;
  background-image: url(../images/plus.png);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  float: left;
  /* align-self: stretch; */
  width: 39px !important;
  margin-right: 15px;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
.ui-accordion-header:hover span {
  background-color: #ffea00;
  background-image: url(../images/plus-black.png);
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
.ui-accordion-header-active span {
  background-color: #ffea00;
  background-image: url(../images/minus-black.png);
  background-position: center center;
  background-repeat: no-repeat;
}

/* TAGS
================================================== */
.tag-cloud ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.tag-cloud li {
  float: left;
}
.tag-cloud a {
  display: inline-block;
  color: #fff;
  text-align: center;
  background-color: #7c8187;
  padding-top: 10px;
  padding-right: 14px;
  padding-bottom: 10px;
  padding-left: 14px;
}
.tag-cloud a:hover {
  color: #414146;
  background-color: #ffea00;
}

/* FLICKR
================================================== */
ul#flickrfeed {
  margin: 0;
  padding: 0;
}
ul#flickrfeed li {
  display: inline-block;
  float: left;
}
ul#flickrfeed li a {
  display: block;
}
ul#flickrfeed li a img {
  height: 93px;
  width: 93px;
}

/* COMMENTS
================================================== */
.all-comments-container {
  background-color: #7c8187;
  padding: 15px;
  color: #fff;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 18px;
}
.single-comment {
  position: relative;
  margin-left: 92px;
  margin-bottom: 20px;
  margin-top: 12px;
}
.comment-avatar {
  position: absolute;
  top: 0px;
  left: -92px;
}
.comment-head {
  background-color: #535961;
}
.comment-list ol {
  margin: 15px 0 15px 0;
  padding: 0;
}
.comment-name {
  padding-left: 15px;
  padding-top: 12px;
  padding-bottom: 9px;
}
.comment-name a {
  color: #fff;
  font-size: 16px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
}
.comment-name a:hover {
  color: #ffea00;
}
.comment-desc {
  font-size: 10px;
  color: #fff;
  margin-right: 12px;
}
.comment-reply {
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
.comment-reply a {
  color: #414146;
  font-size: 12px;
  padding: 12px 15px;
  background-color: #ffea00;
  display: block;
}
.comment-reply a:hover {
  color: #fff;
  background-color: #414146;
}
.comment-text {
  padding-left: 15px;
  padding-right: 15px;
  min-height: 65px;
  background-color: #efefef;
}
.comment-text p {
  margin: 0;
  padding-top: 12px;
  padding-bottom: 12px;
}
li.comment ol.comment-sub {
  margin-left: 40px;
}
.author-comment {
  position: relative;
  margin-left: 0px;
  margin-bottom: 12px;
  margin-top: 12px;
}
.author-avatar {
  position: absolute;
  top: 0px;
  left: 0px;
}
.author-name /*.comment-name*/ {
  padding-left: 109px;
  padding-top: 12px;
  padding-bottom: 9px;
}
.author-name a {
  color: #fff;
  font-size: 16px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
}
.author-name a:hover {
  color: #ffea00;
}
.author-text {
  padding-left: 109px;
  padding-right: 15px;
  min-height: 65px;
  background-color: #efefef;
}
.author-text p {
  margin: 0;
  padding-top: 12px;
  padding-bottom: 12px;
}
/******LEAVE COMMENT FORM*******/
.field-1-1-comment textarea {
  width: 640px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.field-1-2-comment input {
  width: 310px;
  margin-right: 20px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.field-1-2-comment:nth-of-type(2n) input {
  margin-right: 0;
}
.leave-comment-container #contact-form .iconed-box {
  padding-left: 60px;
  width: 310px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
/* PORTFOLIO
============================================*/
ul#portfolio {
  margin: 0;
  padding: 0;
  transition: none !important;
}
.filter-portfolio ul {
  margin: 0;
  padding: 0;
}
ul#filter {
  margin: 0;
  padding: 0;
  float: right;
}
ul#filter li {
  float: left;
  margin: 0;
  padding: 0;
}
ul#filter li a {
  padding: 22px 15px 22px 15px;
  margin: 0;
  color: #fff;
  background-color: #535961;
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
  display: block;
}
ul#filter li a:hover {
  color: #fff;
  background-color: #414146;
}

.filter-portfolio .content-under-container-white {
  margin-bottom: 25px;
}
ul#filter li.current a {
  position: relative;
  background-color: #fff;
  color: #414146;
}
/******PORTFOLIO SINGLE******/
ul.project-details {
  margin: 15px 0 15px 0;
  padding: 0;
}
.project-details li {
  height: 30px;
  margin-top: 10px;
  line-height: 30px;
}
.project-details li:first-child {
  margin-top: 0;
}
.port-cat-icon {
  display: block;
  float: left;
  margin-right: 10px;
  height: 30px;
  width: 31px;
  background-color: #d1d1d1;
  background-image: url(../images/next-small.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.portfolio-pagination {
  float: right;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.portfolio-pagination li {
  float: left;
}
.portfolio-pagination .pag-next {
  background-color: #535961;
  background-image: url(../images/next-small.png);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 62px;
  width: 30px;
}
.portfolio-pagination .pag-prev {
  background-color: #535961;
  background-image: url(../images/prev-small.png);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 62px;
  width: 30px;
}
.portfolio-pagination .pag-prev:hover,
.portfolio-pagination .pag-next:hover {
  background-color: #2f343a;
}
/******************************************/
.portfolio-check-list {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}
.portfolio-check-list > li:before {
  background-attachment: scroll;
  background-color: transparent;
  background-image: url(../images/features-check-icon.png);
  background-position: 0 center;
  background-repeat: no-repeat;
  height: 40px;
  left: 0;
  width: 40px;
}
.portfolio-check-list > li:before {
  content: "";
  position: absolute;
}
.portfolio-check-list > li {
  padding-left: 35px;
  position: relative;
  line-height: 40px;
}
.sidebar-item .content-container-white.padding-l-r-15 p {
  margin: 0;
  padding-top: 12px;
  padding-bottom: 12px;
}

/* ELEMENTS
=======================================*/
h3.title-block {
  padding: 0px;
  line-height: 18px;
  margin-bottom: 20px;
  margin-top: 20px;
}
/******DROPCAPS******/
.dropcap1 {
  background: none repeat scroll 0 0 #535961;
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 30px;
  font-weight: normal;
  height: 44px;
  line-height: 44px;
  margin: 6px 7px 0 0;
  padding-bottom: 2px;
  text-align: center;
  width: 36px;
}
.dropcap2 {
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 30px;
  height: 60px;
  line-height: 55px;
  text-align: center;
  width: 60px;
  border-radius: 70px 70px 70px 70px;
  padding-left: 1px;
  background-attachment: scroll;
  background-color: #7c8187;
  background-image: none;
  background-repeat: repeat;
  background-position: 0 0;
  margin-right: 7px;
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 8px;
}
.dropcap3 {
  color: #7e8082;
  display: inline-block;
  float: left;
  font-size: 56px;
  font-weight: lighter;
  height: 36px;
  line-height: 56px;
  margin: 0 7px 0 0;
  padding-bottom: 2px;
  text-align: center;
  width: 36px;
}
.dropcap4 {
  background: none repeat scroll 0 0 #ffea00;
  color: #414146;
  display: inline-block;
  float: left;
  font-size: 30px;
  font-weight: normal;
  height: 44px;
  line-height: 44px;
  margin: 6px 7px 0 0;
  padding-bottom: 2px;
  text-align: center;
  width: 36px;
}
/******STYLED LIST******/
.styled-list {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}
.styled-list.style-1 > li:before {
  background-position: 0 -30px;
}
.styled-list.style-2 > li:before {
  background-position: 0 -60px;
}
.styled-list.style-3 > li:before {
  background-position: 0 -14px;
}
.styled-list.style-4 > li:before {
  background-position: 0 0;
}
.styled-list.style-5 > li:before {
  background-position: 0 -45px;
}
.styled-list.style-6 > li:before {
  background-position: 0 -75px;
}
.styled-list > li:before {
  height: 15px;
  left: 0;
  top: 1.5px;
  width: 15px;
  background-attachment: scroll;
  background-color: transparent;
  background-image: url(../images/list-icons.png);
  background-repeat: no-repeat;
  background-position: 0 0;
}
.styled-list > li:before {
  content: "";
  position: absolute;
}
.styled-list > li {
  padding-left: 19px;
  position: relative;
}
.demo-list-styles ul {
  margin-bottom: 10px;
}

/* Toggle
=========================================================*/

#toggle-view {
  margin: 0;
  padding: 0;
}
#toggle-view li {
  cursor: pointer;
}
#toggle-view li:first-child {
  margin-top: 0px;
}
#toggle-view .ui-accordion-header-active {
  color: #ffea00;
}
#toggle-view .ui-accordion-header:hover {
  color: #ffea00;
}
#toggle-view .panel {
  display: none;
  margin-left: 39px;
  background-color: #b8bcc1;
  padding: 15px;
}
#toggle-view .panel p {
  margin-top: 0;

  color: #565a60;
  line-height: 28px;
}

.panel p:last-child {
  margin-bottom: 0px;
}

/*******FOR ICONS********/
.the-icons {
  margin: 0;
  padding: 0;
}
.the-icons li i {
  font-size: 21px;
  margin: 0 3px 0 0;
}

/*FEATURES
================================================== */
.features-check-list {
  list-style: none outside none;
  margin: 0;
  padding: 0;
  padding-left: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.features-check-list > li:before {
  background-attachment: scroll;
  background-color: transparent;
  background-image: url(../images/features-check-icon.png);
  background-position: 0 center;
  background-repeat: no-repeat;
  height: 40px;
  left: 0;
  width: 40px;
}
.features-check-list > li:before {
  content: "";
  position: absolute;
}
.features-check-list > li {
  padding-left: 35px;
  position: relative;
  line-height: 40px;
}
.box-icon i {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #565a60;
  background-color: #fff;
  float: left;
  font-size: 18px;
  margin: 0;
  text-align: center;
  height: 67px;
  width: 67px;
  padding-top: 24px;
  margin-right: 15px;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
.box-icon:hover i {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  background-color: #ffea00;
}
.box-icon-container p {
  margin: 0;
}
.box-icon p,
.box-icon h3 {
  margin: 0;
  padding: 0;
}
.box-icon h3 {
  color: #535961;
  font-size: 16px;
  line-height: 25px;
  padding-top: 13px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
}

/*FEATURES 2
================================================== */

.features-2-text {
  margin-top: 45px;
  padding-bottom: 20px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  color: #565a60;
  font-size: 33px;
  line-height: 41px;
  border-bottom: 5px #fff solid;
}
.features-2-container-img {
  margin-top: 35px;
}

/* FOOTER
================================================== */
.yellow {
  color: #ffea00;
}
.title-font-24 {
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  color: #fdfdfd;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
}
.title-font-12 {
  margin-top: 8px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  color: #d7d7d7;
  font-size: 12px;
}
.footer-social-text-container {
  margin-bottom: 32px;
  margin-top: 38px;
}
.footer-content-container {
  background-color: #fff;
  padding: 51px 40px 51px 40px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  color: #414146;
  font-size: 24px;
  line-height: 28px;

  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
footer {
  color: #d1d1d1;
}
.caption.footer-block {
  color: #414146;
  background-color: #fff;
  display: inline-block;
  padding: 25px;
  margin: 0;
}
.right {
  float: right;
}
.left {
  float: left;
}
.right-text {
  text-align: right;
}
.center-text {
  text-align: center;
}
.footer-content-bg {
  background-color: #414146;
}
footer .caption {
  float: none;
  color: #fff;
}
.logo-footer {
  /* background-image: url(../images/logo-footer.png); */
  background-position: left top;
  background-repeat: no-repeat;
  float: left;
  height: 15px;
  width: 88px;
  margin-bottom: 8px;
  margin-top: 8px;
}
footer h3,
footer h4 {
  font-weight: normal;
}

/******************************************************/
ul.latest-post li {
  display: block;
  margin-left: 56px;
  padding-top: 6px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
  position: relative;
}
ul.footer-contact-info li {
  margin: 0;
  padding: 0;
  display: block;
  margin-left: 56px;
  padding-top: 9px;
  padding-right: 15px;
  padding-bottom: 11px;
  padding-left: 15px;
  position: relative;
  min-height: 56px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.standart-post:before,
.standart-post:after {
  content: "";
  position: absolute;
}
.standart-post:after {
  top: 0px;
  left: -56px;
  width: 56px;
  height: 56px;
  background-image: url(../images/icon-standart-post.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #565a60;
}
.image-post:before,
.image-post:after {
  content: "";
  position: absolute;
}
.image-post:after {
  top: 0px;
  left: -56px;
  width: 56px;
  height: 56px;
  background-image: url(../images/icon-image-post.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #565a60;
}
.video-post:before,
.video-post:after {
  content: "";
  position: absolute;
}
.video-post:after {
  top: 0px;
  left: -56px;
  width: 56px;
  height: 56px;
  background-image: url(../images/icon-video-post.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #565a60;
}
.footer-loc:before,
.footer-loc:after {
  content: "";
  position: absolute;
}
.footer-loc:after {
  top: 0px;
  left: -56px;
  width: 56px;
  height: 75px;
  background-image: url(../images/icon_footer_loc.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #565a60;
}
.footer-phone:before,
.footer-phone:after {
  content: "";
  position: absolute;
}
.footer-phone:after {
  top: 0px;
  left: -56px;
  width: 56px;
  height: 56px;
  background-image: url(../images/icon_footer_phone.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #565a60;
}
.footer-mail:before,
.footer-mail:after {
  content: "";
  position: absolute;
}
.footer-mail:after {
  top: 0px;
  left: -56px;
  width: 56px;
  height: 56px;
  background-image: url(../images/icon_footer_mail.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #565a60;
}
.latest-post h4 {
  line-height: 18px;
  padding: 0;
  margin: 0;
}
.latest-post h4 a {
  color: #fff;
  font-weight: normal;
  font-size: 12px;
}
.latest-post h4 a:hover {
  color: #ffea00;
}
/******************************************************/
ul.latest-post,
ul.footer-contact-info {
  padding: 0;
  margin: 0;
}
.date-post-footer {
  color: #d1d1d1;
  font-size: 11px;
  line-height: 11px;
  font-style: italic;
}
.footer-mail a {
  color: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.footer-mail a:hover {
  color: #ffea00;
}
.footer-copyright-bg {
  color: #a1a1a1;
  background-color: #27282a;
}
.footer-copyright-bg .footer-menu-container {
  padding-top: 25px;
  padding-bottom: 20px;
  float: left;
}
.footer-copyright-container {
  float: right;
  margin-top: 15px;
  margin-bottom: 15px;
}
.footer-menu {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
#footer-nav li {
  background: none repeat scroll 0 0 transparent;
  display: inline-block;
  list-style: none outside none;
  margin: 0 -3px 0 0;
}
#footer-nav li:first-child a {
  padding-left: 0;
}
#footer-nav li a {
  display: block;
  padding: 0 15px;
  color: #a1a1a1;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
}
#footer-nav li a:hover {
  color: #ffea00;
}
#footer-nav li:last-child a {
  padding-right: 0;
}
a.author {
  color: #cecece;
}
a.author:hover {
  color: #ffea00;
}
footer a.r-m-plus {
  color: #fff;
}
footer .r-m-plus:after {
  background: #404040;
  background-image: url(../images/plus.png);
  background-position: center center;
  background-repeat: no-repeat;
}

/* TWITTER
================================================== */
.tweet_list {
  margin: 0;
  padding: 0;
}
.tweet_list li {
  padding-bottom: 12px;
  padding-top: 0px;
}
.tweet_list li:last-child {
  border: none;
  padding-bottom: 0px;
}
.tweet_time {
  display: block;
  text-align: right;
}
.tweet_time a {
  color: #d1d1d1;
  font-style: italic;
  background-color: #7c8187;
  padding-top: 7px;
  padding-right: 10px;
  padding-bottom: 7px;
  padding-left: 10px;
  line-height: 31px;
}
.at {
  color: #fff;
}
.tweet_text a {
  color: #fff;
}
.tweet_text a:hover,
.tweet_time a:hover {
  color: #ffea00;
}

.tweet_text {
  background-color: #535961;
  display: block;
  margin-left: 38px;
  padding-top: 9px;
  padding-right: 15px;
  padding-bottom: 11px;
  padding-left: 15px;
  position: relative;
  word-wrap: break-word;
}

.tweet_text:before,
.tweet_text:after {
  content: "";
  position: absolute;
}
.tweet_text:after {
  top: 0px;
  left: -38px;
  width: 38px;
  height: 38px;
  background-image: url(../images/icon-twitter.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #2f343a;
}

/* PRICING TABLE
=========================================*/
.first-column {
  width: 210px;
  height: auto;
  float: left;
}
.first-column ul {
  margin: 141px 0 0 0;
}
.first-column ul li {
  width: 100%;
  height: 40px;
  background: #f5f5f5;
  margin: 0;
  display: block;
  color: #6b6b6b;
  font-size: 12px;
  line-height: 40px;
  text-indent: 20px;
}
.first-column ul li.alt {
  background: #fafafa;
}
.price-col-gray {
  float: left;
  width: 235px;
  height: auto;
  text-align: center;
  margin: 20px 0 0 0;
}
.m-left-0 {
  margin-left: 0 !important;
}
.price-col-gray h1 {
  margin: 0;
  padding-bottom: 35px;
  padding-top: 35px;
  height: 40px;
  display: block;
  background-color: #535961;
  color: #fcfcfc;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 40px;
  font-weight: normal;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
}
.month {
  color: #808080;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 14px;
  background-color: #fff;
  padding: 14px 0 14px 0;
  margin: 0;
}
.price-col-gray ul {
  margin: 0;
  padding: 0;
}
.price-col-gray ul li {
  height: 40px;
  background: #fff;
  margin: 0;
  display: block;
  color: #6b6b6b;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #efefef;

  margin-left: 5px;
  margin-right: 5px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.price-col-gray ul li:last-child {
  border-bottom: none;
}
.price-button-container {
  background-color: #f5f5f5;
  height: 75px;
}
.price-button-container-main {
  background-color: #f5f5f5;
  height: 95px;
}
a.button.medium.price-button {
  display: inline-block;
  font-size: 14px;
  height: 100%;
  line-height: 72px;
  padding: 0;
  width: 100%;
  font-size: 18px;
  cursor: pointer;
}
.price-col-gray2 a.button.medium.price-button:hover {
  color: #fff;
}
.price-button-container-main a.button.medium.price-button {
  line-height: 92px;
}
.price-marginleft-20 {
  margin-left: -20px;
}
.price-col-main {
  float: left;
  width: 234px;
  height: auto;
  text-align: center;
  margin: 0 /*0 0 2px*/;
  position: relative;
  z-index: 20;
}
.price-col-main h1 {
  margin: 0;
  padding-bottom: 40px;
  padding-top: 41px;
  height: 49px;
  display: block;
  background-color: #ffea00;
  color: #414146;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 49px;
  font-weight: normal;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
}
.price-col-main p.white {
  height: 30px;
  font-size: 12px;
  font-weight: normal;
  display: block;
  background: #ffbe11;
  color: #fff;
  margin: 0;
}
.price-col-main ul {
  margin: 0;
  padding: 0;
  background: none repeat scroll 0 0 #d8dadd;
}
.price-col-main ul li {
  -moz-box-sizing: border-box;

  border-bottom: 1px solid #efefef;
  color: #6b6b6b;
  display: block;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  margin: 0 5px;
  text-align: center;
}

.price-col-main ul li:last-child {
  border-bottom: none;
}

.col-border {
  border-left: 1px solid #d1d1d1;
}
.col-border-right {
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
}
.currency,
.price,
.cents {
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  display: inline-block;
  color: #fff;
}
.price-col-main .currency,
.price-col-main .price,
.price-col-main .cents {
  color: #fff;
}
.currency {
  font-size: 30px;
}
.price {
  font-size: 40px;
  line-height: 50px;
}

.price-container2 .price {
  font-size: 60px;
  line-height: 50px;
}
.cents {
  font-size: 30px;
  position: absolute;
  top: 19px;
}
.price-container {
  position: relative;
  padding-bottom: 25px;
  padding-top: 25px;
  background-color: #d6d6d6;
}
.cents-cont {
  width: 5px;
  font-size: 20px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
}
.place {
  display: inline-block;
  height: 10px;
  width: 25px;
  color: #9da2a8;
}
.price-col-main .price-container {
  background-color: #535961;
}

/* PRICING TABLE 2
=========================================*/
.place2 {
  display: inline-block;
  height: 13px;
  width: 35px;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #bcbcbc;
}
.price-col-gray2 {
  float: left;
  width: 223px;
  height: auto;
  text-align: center;
  margin: 0px 0 0 5px;
}
.price-col-gray2 h1 {
  margin: 0;
  padding-bottom: 0px;
  padding-top: 30px;
  height: 70px;
  display: block;
  background-color: #fff;
  color: #535961;
  text-transform: uppercase;
  font-size: 34px;
  line-height: 40px;
  font-weight: normal;
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
}
.price-col-gray2 ul {
  margin: 0;
  padding: 0;
}
.price-col-gray2 ul {
  background-color: #fff;
}
.price-col-gray2 ul li {
  height: 40px;
  background: #fff;
  margin: 0;
  display: block;
  color: #6b6b6b;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #efefef;

  margin-left: 5px;
  margin-right: 5px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.price-col-gray2 ul li:last-child {
  border-bottom: none;
}
.price-container2 {
  background-color: #535961;
  padding-bottom: 10px;
  padding-top: 15px;
  position: relative;
}
.col-border2 {
  border: 1px solid #d1d1d1;
  margin-bottom: 1px;
}
.pt-col-main {
  background-color: #ffea00;
}
h1.pt-col-main {
  background-color: #ffea00;
  color: #414146;
}
.pt-col-main .currency,
.pt-col-main .price,
.pt-col-main .cents,
.pt-col-main .place2 {
  color: #fff;
}
.price-table-grey-bg {
  background-color: #efefef;
  padding: 16px;
}
/***********ICONS*****************/
li [class^="icon-"],
.nav li [class^="icon-"],
li [class*=" icon-"],
.nav li [class*=" icon-"] {
  display: inline-block;
  text-align: center;
  width: 1.25em;
}

/* ABOUT US
================================================== */
.team-name h5 {
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
}
.team-name span {
  color: #888888;
  display: block;
  font-weight: 400;
}

/* A, BUTTONS, A-BUTTONS
================================================== */

/**********READ ME*************/
a.r-m-plus {
  position: relative;
  display: inline-block;
}
a.r-m-plus:before,
a.r-m-plus:after {
  content: "";
  position: absolute;
}
.r-m-plus:after {
  width: 36px;
  height: 36px;
  background-color: #ffea00 !important;
  background-image: url(../images/arrow-rm.png);
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
a.r-m-full {
  background-color: #414146;
  color: #fff;
  margin-right: 36px;
}
a.r-m-full:hover {
  background-color: #ffea00;
  color: #414146;
}
a.r-m-full:after {
  right: -36px !important;
  top: 0px !important;
}
a.r-m-plus-small {
  background-color: #ffea00 !important;
  background-image: url(../images/arrow-rm.png);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 36px;
  width: 36px;
  text-indent: -9999px;
}
a.r-m-plus-small:hover {
  background-color: #414146 !important;
  background-image: url(../images/arrow-rm-hover.png);
  background-position: center center;
  background-repeat: no-repeat;
}

/**********BUTTONS*************/
button {
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
.button {
  font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  background-color: #ffea00;
  color: #414146;
  border: none;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
.button:hover {
  background-color: #535961;
  color: #fff;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
.button.medium {
  font-size: 14px;
  line-height: 36px;
  height: 36px;
  padding: 0px 20px 0px 20px;
  display: inline-block;
}
.button.large {
  font-size: 20px;
  line-height: 20px;
  padding: 15px 39px 15px 39px;
  display: inline-block;
}

.button.small {
  font-size: 12px;
  line-height: 16px;
  padding: 6px 10px 7px 10px;
  display: inline-block;
}

.button.yellow {
  background-color: #ffea00;
  color: #414146 !important;
  border: none;
}
.button.yellow:hover {
  background-color: #535961;
  color: #fff !important;
}
.button.blue {
  background-color: #2995cc;
  color: #fff;
}
.button.blue:hover {
  background-color: #535961;
}
.button.aqua {
  background-color: #1bbc9b;
  color: #ffffff;
}
.button.aqua:hover {
  background-color: #535961;
}
.button.gray {
  color: #fff;
  background-color: #535961;
  border: none;
}
.button.gray:hover {
  background-color: #ffea00; /*Change color*/
  cursor: pointer;
}
.button.orange {
  color: #fff;
  background-color: #e67e22;
  border: none;
}
.button.orange:hover {
  background-color: #535961;
  cursor: pointer;
}
.button.green {
  color: #fff;
  background-color: #aadd00;
  border: none;
}
.button.green:hover {
  background-color: #535961;
  cursor: pointer;
}
.demo-buttons a {
  margin-bottom: 5px;
}
.button i {
  margin: 0 5px 0 -1px;
}
.button.medium i,
.button.medium-border i {
  margin: 0 6px 0 -4px;
}
.button.large i,
.button.large-border i {
  margin: 0 10px 0 -15px;
}
.button.black {
  font-family: "Open Sans", sans-serif;
  color: #ffffff;
  font-style: normal;
  background-color: #4d4d4d;
}
.button.black:hover {
  background-color: #575757;
}
a {
  color: #ffea00;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
a:hover {
  color: #2f343a;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}
.a-invert {
  color: #535961;
}
.a-invert:hover {
  color: #ffea00;
}
.button.get-theme {
  font-size: 14px;
  padding: 10px 17px 10px 40px;

  background: url(../images/icon-get-theme-black.png) no-repeat scroll 14px center;
  background-color: #ffea00;
  display: inline-block;
}
.button.get-theme:hover {
  background: url(../images/icon-get-theme.png) no-repeat scroll 14px center;
  background-color: #535961;
}
/* MARGINS
================================================== */
.m-bot-10 {
  margin-bottom: 10px !important;
}
.m-bot-15 {
  margin-bottom: 15px !important;
}
.m-bot-20 {
  margin-bottom: 20px !important;
}
.m-bot-25 {
  margin-bottom: 25px !important;
}
.m-top-25 {
  margin-top: 25px !important;
}
.m-bot-30 {
  margin-bottom: 30px !important;
}
.m-bot-33 {
  margin-bottom: 33px !important;
}
.m-top-30 {
  margin-top: 30px !important;
}
.m-bot-35 {
  margin-bottom: 35px !important;
}
.m-bot-55 {
  margin-bottom: 55px !important;
}
.m-bot-50 {
  margin-bottom: 50px !important;
}
.m-bot-60 {
  margin-bottom: 60px !important;
}
.m-bot-100 {
  margin-bottom: 100px !important;
}
.m-top-60 {
  margin-top: 60px !important;
}
.m-top-80 {
  margin-top: 80px !important;
}
.m-top-100 {
  margin-top: 100px !important;
}
.m-bot-135 {
  margin-bottom: 135px !important;
}
.min-m-top-75 {
  margin-top: -75px !important;
  z-index: 99;
}
.m-t-min-1 {
  margin-top: -1px !important;
}
.m-none {
  margin: 0 !important;
}
.m-minus,
.m-minus-main {
  margin-top: -58px !important;
}
.m-t-b-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.m-b-20 {
  margin-bottom: 20px;
}

.pad-b-0 {
  padding-bottom: 0px !important;
}
.pad-t-15 {
  padding-top: 15px !important;
}
.pad-t-b-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* HOVER EFFECT
================================================== */
.view .mask,
.view .content {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  text-align: center;
}
.view img {
  display: block;
  position: relative;
}
.view a.link {
  padding: 0;
  margin: 0;
  height: 44px;
  width: 44px;
  display: block; /**/
  float: left;
  text-decoration: none;
  background-color: #fff;
  background-image: url(../images/link-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.view a.zoom {
  padding: 0;
  margin: 0;
  height: 44px;
  width: 44px;
  display: block; /**/
  float: left;
  text-decoration: none;
  background-color: #fff;
  background-image: url(../images/zoom-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.view a.zoom:hover,
.view a.link:hover {
  background-color: #ffea00;
}
.hover-item img {
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.hover-item .abs {
  position: absolute;
  bottom: -47px;
  left: 0px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.hover-item:hover .abs {
  bottom: 0px;
  left: 0px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.portfolio-item .hover-item .abs {
  position: absolute;
  bottom: -47px;
  left: 0px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.portfolio-item .hover-item:hover .abs {
  bottom: 0px;
  left: 0px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.blog-item .hover-item .abs {
  position: absolute;
  bottom: -47px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.blog-item .hover-item:hover .abs {
  bottom: 0px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.hover-item:hover {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
}
.hover-item:hover .item-caption,
.hover-item:hover .lw-item-caption-container a {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}
.hover-item:hover .lw-item-caption-container a:hover {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
}

.view-first a.info {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/*****HOVER FOR BLOG *****/
/*.blog-item .view .mask h2 {
  margin-bottom: 25px;
  margin-top: 80px;
}
.view-first:hover p {
   -webkit-transition-delay: 0.1s;
   -moz-transition-delay: 0.1s;
   -o-transition-delay: 0.1s;
   -ms-transition-delay: 0.1s;
   transition-delay: 0.1s;
}
.view-first:hover a.info {
   -webkit-transition-delay: 0.2s;
   -moz-transition-delay: 0.2s;
   -o-transition-delay: 0.2s;
   -ms-transition-delay: 0.2s;
   transition-delay: 0.2s;
}*/

/* BACK TO TOP BUTTON
================================================== */
/******BACK TO TOP BUTTON******/
#back-top {
  position: fixed;
  bottom: 45px;
  right: 25px;
  padding: 0;
  margin: 0;
  z-index: 999999999;
}
#back-top a {
  text-decoration: none;
  width: 38px;
  height: 38px;
  display: block;
  background-color: #2f343a;
  background-image: url(../images/back-top.png);
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 9999999;
}
#back-top a:hover {
  background-color: #ffea00;
  background-image: url(../images/back-top-black.png);
  background-repeat: no-repeat;
  background-position: center center;
}

/******COLUMNS******/
.center {
  margin: 0 auto;
  max-width: 960px;
  width: 100%;
}

/* MEDIA QUERIES
================================================== */

@media only screen and (min-width: 768px) and (max-width: 959px) {
  #fdw nav ul li {
    margin-left: 12px;
  }
}

/*****FOR MOBILE ******/
@media only screen and (max-width: 767px) {
  /* NAV MENU */
  nav#main-nav ul {
    display: none;
  }
  nav#main-nav select {
    width: 420px;
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
    cursor: pointer;
    padding: 10px;
    border: none;
    color: #777;
    font-family: "OswaldLight", Arial, Helvetica, sans-serif;
  }
}

/* #Tablet (Portrait) (2)
================================================== */

/* Note: Design for a width of 768px */

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .header-rl-container {
    width: 579px;
  }

  html body div.jcarousel {
    padding-bottom: 1px;
  }
  /*****BUY NOW******/
  .buy-now-text-container {
    width: 555px;
  }
  .side-box-bg.buy-now {
    height: 116px;
  }
  /********BOXES**********/
  .box2-text-container h3.long-text {
    padding: 26px 20px 0px 115px;
  }
  .box3-text-container h3.long-text {
    padding: 30px 20px 0px 115px;
  }
  /***********404**********/
  .error404-main-text h2 {
    margin-left: 40px;
  }
  .error404-numb {
    font-size: 180px;
    line-height: 180px;
    margin-right: 40px;
  }

  .error404-text {
    margin-right: 25px;
    font-size: 55px;
    line-height: 55px;
  }
  /*****CONTACT FORM*****/
  .field-1-3 input {
    width: 172px;
    margin-right: 20px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  .field-1-1 textarea {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    width: 556px;
  }
  #contact-form .iconed-box {
    padding-left: 20px;
    width: 172px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  /*****NEWS LETTER & BUY NOW*****/
  .nl-text {
    display: none;
  }
  .nl-text-container.part {
    width: 282px;
  }
  /******TABS BLOG*****/
  .lp-title-cont {
    width: 95px;
  }
  /******OURCLIENTS*****/
  .our-clients-container li:nth-child(5) {
    display: none;
  }
  .our-clients-container li {
    width: 187px;
  }
  /******BLOG******/
  .blog-info-container ul li.tag {
    display: none;
  }
  /******FLICKR******/
  ul#flickrfeed li a img {
    height: 73px;
    width: 73px;
  }
  /******LEAVE COMMENT FORM*******/
  .field-1-1-comment textarea {
    width: 508px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  .field-1-2-comment input {
    width: 244px;
    margin-right: 20px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  .leave-comment-container #contact-form .iconed-box {
    padding-left: 60px;
    width: 244px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  /******PRICING TABLE******/
  .price-col-gray {
    width: 187px;
  }
  .price-col-main {
    width: 186px;
  }
  .pricebox-marginleft {
    margin-left: -20px;
  }
  .price-col-gray2 {
    width: 175px;
  }
}

/*  #Mobile (Portrait) (4)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
  nav#main-nav select {
    width: 300px;
  }
  .header-rl-container {
    width: 300px;
  }
  .search-text {
    width: 100px;
  }
  .footer-copyright-bg {
    padding-top: 5px;
    padding-bottom: 20px;
  }
  #footer-nav li {
    display: list-item;
  }
  #footer-nav li a {
    color: #a1a1a1;
    padding: 0;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .footer-copyright-bg .footer-menu-container {
    float: none;
    padding-bottom: 15px;
    padding-top: 5px;
  }
  .footer-copyright-container {
    float: none;
    text-align: center !important;
    color: #7c8187;
  }
  /*****FEATURES******/
  .features-demo article:first-child {
    margin-top: 0 !important;
  }
  /*********CAPTION*************/
  .caption-block {
    right: 0px;
  }
  /*****BUY NOW******/
  .buy-now-text-container {
    width: 270px;
  }
  .side-box-bg.buy-now {
    display: none;
  }
  .button-buy-container {
    margin-left: 75px;
  }
  /*****CONTACT FORM******/
  .field-1-3 input {
    width: 300px;
    margin-right: 0px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  .field-1-1 textarea {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    width: 300px;
  }
  #contact-form .iconed-box {
    /*padding-left: 60px;*/
    width: 300px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  /***********404**********/
  .error404-main-text h2 {
    font-size: 18px;
    line-height: 22px;
  }
  .error404-numb {
    font-size: 120px;
    line-height: 120px;
    margin-right: 30px;
  }
  .error404-text {
    font-size: 30px;
    line-height: 30px;
    margin-left: 34px;
  }
  .error404-container {
    margin-top: 35px;
  }
  .error404-numb {
    text-align: center;
    margin-right: 0;
  }
  .error404-text {
    text-align: center;
    margin-left: 0;
  }
  /*****NEWS LETTER*****/
  .nl-text {
    display: none;
  }
  .nl-text-container.part {
    width: 1px;
  }
  .nl-text-container .caption {
    display: none;
  }
  .newsletterform input {
    width: 128px;
  }
  .nl-img-container {
    display: none;
  }
  .sixteen .side-box-bg.news-letter.hide {
    display: none;
  }
  /******OUR CLIENTS******/
  .our-clients-container li:nth-child(5) {
    display: none;
  }
  .our-clients-container li:nth-child(4) {
    display: none;
  }
  .our-clients-container li:nth-child(3) {
    display: none;
  }
  .our-clients-container li:nth-child(2) {
    display: none;
  }
  .our-clients-container li {
    margin-left: 55px;
  }
  /*****FOOTER*****/
  .m-minus {
    margin-top: 0 !important;
  }
  /******BACK TO TOP BUTTON******/
  #back-top {
    position: fixed;
    bottom: 40px;
    right: 10px;
    padding: 0;
    margin: 0;
  }
  /*****TABS BLOG*****/
  .lp-title-cont {
    width: 175px;
  }
  /******BLOG******/
  .blog-info-container ul li.tag {
    display: none;
  }
  .blog-info-container ul li.view {
    display: none;
  }
  /******FLICKR******/
  ul#flickrfeed li a img {
    height: 100px;
    width: 100px;
  }
  /******LEAVE COMMENT FORM*******/
  .field-1-1-comment textarea {
    width: 300px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  .field-1-2-comment input {
    width: 300px;
    margin-right: 0px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  .leave-comment-container #contact-form .iconed-box {
    padding-left: 60px;
    width: 300px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  .comment-desc {
    margin-left: 5px;
    margin-right: 5px;
  }
  /******PRICING TABLE*****/
  .price-col-gray,
  .price-col-main,
  .price-col-gray2 {
    float: none;
    width: 268px;
    left: 0;
    margin: 0 auto 20px !important;
  }
  .col-border {
    border-left: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
  }
  /*****CAROUSEL*****/
  body .container .columns.carousel-intro {
    display: block;
    float: none !important;
  }
}

/* #Mobile (Landscape) (3)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
  nav#main-nav select {
    width: 420px;
    display: block;
  }
  .header-rl-container {
    width: 420px;
  }
  .header-rr-container {
    margin-bottom: 10px;
    margin-top: 25px;
  }
  .search-text {
    width: 75px;
  }
  .footer-copyright-bg .footer-menu-container {
    float: none;
    padding-bottom: 15px;
    padding-top: 5px;
  }
  .footer-copyright-container {
    float: none;
    text-align: center !important;
  }
  /*********CAPTION*************/
  .caption-block {
    right: 0px;
  }
  /***********BUY NOW**********/
  .buy-now-text-container {
    width: 390px;
  }
  .button-buy-container {
    margin-left: 135px;
  }
  .side-box-bg.buy-now {
    display: none;
  }
  /*****CONTACT FORM*****/
  .field-1-3 input {
    width: 420px;
    margin-right: 0px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  .field-1-1 textarea {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    width: 420px;
  }
  #contact-form .iconed-box {
    width: 420px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  /***********404**********/
  .error404-main-text h2 {
    font-size: 18px;
    line-height: 22x;
  }
  .error404-numb {
    font-size: 120px;
    line-height: 120px;
  }

  .error404-text {
    font-size: 28px;
    line-height: 32px;
  }
  .error404-container {
    margin-top: 55px;
  }
  .error404-numb {
    text-align: center;
    margin-right: 0;
  }
  .error404-text {
    text-align: center;
    margin-left: 0;
  }
  /*****NEWS LETTER*****/
  .nl-text {
    display: none;
  }
  .nl-text-container.part {
    width: 1px;
  }
  .nl-text-container .caption {
    display: none;
  }
  .newsletterform input {
    width: 167px;
  }
  .nl-img-container {
    display: block;
  }
  .sixteen .side-box-bg.news-letter.hide {
    display: block;
  }
  /*****FEATURES******/
  .features-demo article:first-child {
    margin-top: 0 !important;
  }
  /*****OUR CLIENTS*****/
  .our-clients-container li:nth-child(5) {
    display: none;
  }
  .our-clients-container li:nth-child(4) {
    display: none;
  }
  .our-clients-container li:nth-child(3) {
    display: none;
  }
  .our-clients-container li:nth-child(2) {
    display: block;
  }
  .our-clients-container li {
    margin-right: 11px;
    margin-left: 11px;
  }
  /*****FOOTER*****/
  .m-minus {
    margin-top: 0 !important;
  }
  /******BACK TO TOP BUTTON******/
  #back-top {
    position: fixed;
    bottom: 45px;
    right: 10px;
    padding: 0;
    margin: 0;
  }
  /*****TABS BLOG*****/
  .lp-title-cont {
    width: 295px;
  }
  /******BLOG******/
  .blog-info-container ul li.tag {
    display: none;
  }
  .blog-info-container ul li.view {
    display: list-item;
  }
  /******FLICKR******/
  ul#flickrfeed li a img {
    width: 70px;
    height: 70px;
  }
  /******LEAVE COMMENT FORM*******/
  .field-1-1-comment textarea {
    width: 420px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  .field-1-2-comment input {
    width: 420px;
    margin-right: 0px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  .leave-comment-container #contact-form .iconed-box {
    padding-left: 60px;
    width: 420px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  .comment-head .right {
    border: none;
  }
  /******PRICING TABLE*****/
  .price-col-gray,
  .price-col-main,
  .price-col-gray2 {
    float: none;
    width: 268px;
    left: 0;
    margin: 0 auto 20px !important;
  }
  .col-border {
    border-left: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
  }
}

/* Retina Images (High Resolution)
====================================================================== */
@media (-webkit-min-device-pixel-ratio: 1.5),
  (-moz-min-device-pixel-ratio: 1.5),
  (-o-min-device-pixel-ratio: 3/2),
  (min-device-pixel-ratio: 1.5),
  (min-resolution: 1.5dppx) {
  .search-submit {
    background-image: url(../images/search-submit@2x.png) !important;
    background-size: 12px auto !important;
  }
  .facebook-link {
    background-image: url(../images/social/facebook@2x.png);
    background-size: 24px auto;
  }
  .dribbble-link {
    background-image: url(../images/social/dribbble@2x.png);
    background-size: 24px auto;
  }
  .flickr-link {
    background-image: url(../images/social/flickr@2x.png);
    background-size: 24px auto;
  }
  .googleplus-link {
    background-image: url(../images/social/googleplus@2x.png);
    background-size: 24px auto;
  }
  .linkedin-link {
    background-image: url(../images/social/linkedin@2x.png);
    background-size: 24px auto;
  }
  .pintrest-link {
    background-image: url(../images/social/pintrest@2x.png);
    background-size: 24px auto;
  }
  .rss-link {
    background-image: url(../images/social/rss@2x.png);
    background-size: 24px auto;
  }
  .skype-link {
    background-image: url(../images/social/skype@2x.png);
    background-size: 24px auto;
  }
  .twitter-link {
    background-image: url(../images/social/twitter@2x.png);
    background-size: 24px auto;
  }
  .vimeo-link {
    background-image: url(../images/social/vimeo@2x.png);
    background-size: 24px auto;
  }

  .facebook-link:hover {
    background-image: url(../images/social/facebook-hover@2x.png);
    background-size: 24px auto;
  }
  .dribbble-link:hover {
    background-image: url(../images/social/dribbble-hover@2x.png);
    background-size: 24px auto;
  }
  .flickr-link:hover {
    background-image: url(../images/social/flickr-hover@2x.png);
    background-size: 24px auto;
  }
  .googleplus-link:hover {
    background-image: url(../images/social/googleplus-hover@2x.png);
    background-size: 24px auto;
  }
  .linkedin-link:hover {
    background-image: url(../images/social/linkedin-hover@2x.png);
    background-size: 24px auto;
  }
  .pintrest-link:hover {
    background-image: url(../images/social/pintrest-hover@2x.png);
    background-size: 24px auto;
  }
  .rss-link:hover {
    background-image: url(../images/social/rss-hover@2x.png);
    background-size: 24px auto;
  }
  .skype-link:hover {
    background-image: url(../images/social/skype-hover@2x.png);
    background-size: 24px auto;
  }
  .twitter-link:hover {
    background-image: url(../images/social/twitter-hover@2x.png);
    background-size: 24px auto;
  }
  .vimeo-link:hover {
    background-image: url(../images/social/vimeo-hover@2x.png);
    background-size: 24px auto;
  }

  /* .img-holder.comp {
      background-image: url(../images/icon-comp@2x.png);
      background-size: auto 60px ;
    }
    .img-holder.seo {
      background-image: url(../images/icon-seo@2x.png);
      background-size: auto 60px ;
    }
    .img-holder.gears {
      background-image: url(../images/icon-gears@2x.png);
      background-size: auto 60px ;
    } */
  .jcarousel-prev {
    background-image: url(../images/prev-small@2x.png);
    background-size: auto 9px;
  }
  .jcarousel-next {
    background-image: url(../images/next-small@2x.png);
    background-size: auto 9px;
  }
  .jcarousel-prev:hover {
    background-image: url(../images/prev-small-black@2x.png);
    background-size: auto 9px;
  }
  .jcarousel-next:hover {
    background-image: url(../images/next-small-black@2x.png);
    background-size: auto 9px;
  }
  /**************************************************/
  .features-check-list > li:before {
    background-image: url(../images/features-check-icon@2x.png);
    background-size: auto 30px;
  }
  .portfolio-check-list > li:before {
    background-image: url(../images/features-check-icon@2x.png);
    background-size: auto 30px;
  }
  .standart-post:after {
    background-image: url(../images/icon-standart-post@2x.png);
    background-size: auto 19px;
  }
  .image-post:after {
    background-image: url(../images/icon-image-post@2x.png);
    background-size: auto 19px;
  }
  .video-post:after {
    background-image: url(../images/icon-video-post@2x.png);
    background-size: auto 19px;
  }
  .footer-loc:after {
    background-image: url(../images/icon_footer_loc@2x.png);
    background-size: auto 19px;
  }
  .footer-phone:after {
    background-image: url(../images/icon_footer_phone@2x.png);
    background-size: auto 19px;
  }
  .footer-mail:after {
    background-image: url(../images/icon_footer_mail@2x.png);
    background-size: auto 19px;
  }
  #back-top a {
    background-image: url(../images/back-top@2x.png);
    background-size: auto 19px;
  }
  .flex-direction-nav .flex-prev {
    background-image: url(../images/prev-small@2x.png) !important;
    background-size: auto 19px !important;
  }
  .flex-direction-nav .flex-next {
    background-image: url(../images/next-small@2x.png) !important;
    background-size: auto 19px !important;
  }
  .flex-direction-nav .flex-prev:hover {
    background-image: url(../images/prev-small-black@2x.png) !important;
    background-size: auto 19px !important;
  }
  .flex-direction-nav .flex-next:hover {
    background-image: url(../images/next-small-black@2x.png) !important;
    background-size: auto 19px !important;
  }
  .lp-item-text-container blockquote:before {
    background-image: url(../images/quote2@2x.png) !important;
    background-size: auto 19px;
  }
  .contact-loc:after {
    background-image: url(../images/icon_footer_loc-black@2x.png);
    background-size: auto 19px;
  }
  .contact-phone:after {
    background-image: url(../images/icon_footer_phone-black@2x.png);
    background-size: auto 19px;
  }
  .contact-mail:after {
    background-image: url(../images/icon_footer_mail-black@2x.png);
    background-size: auto 19px;
  }
  .view a.link {
    background-image: url(../images/link-icon@2x.png);
    background-size: 21px auto;
  }
  .view a.zoom {
    background-image: url(../images/zoom-icon@2x.png);
    background-size: 21px auto;
  }
  .quote-content {
    background-image: url(../images/quote@2x.png);
    background-size: 19px auto;
  }
  .footer-content-bg a.scrollup {
    /* background-image: url(../images/scrollup@2x.png); */
    background-size: 32px auto;
  }
  .logo-footer {
    /* background-image: url(../images/logo-footer@2x.png); */
    background-size: 88px auto;
  }
  .r-m-plus:after {
    background: url(../images/arrow-rm@2x.png) no-repeat scroll center center;
    background-size: 13px auto;
  }
  a.r-m-plus-small {
    background: url(../images/arrow-rm@2x.png) no-repeat scroll center center;
    background-size: 13px auto;
  }
  footer .r-m-plus:after {
    background: url(../images/plus@2x.png) no-repeat scroll center center #404040;
    background-size: 7px auto;
  }
  .icon_mail {
    background: url(../images/icon_footer_mail@2x.png) no-repeat scroll left 5px transparent;
    background-size: 14px auto;
  }
  .icon_phone {
    background: url(../images/icon_footer_phone@2x.png) no-repeat scroll left 4px transparent;
    background-size: 14px auto;
  }
  .icon_loc {
    background: url(../images/icon_footer_loc@2x.png) no-repeat scroll left 3px transparent;
    background-size: 14px auto;
  }
  .contact-info-page .icon_loc {
    background: url(../images/icon_footer_loc@2x.png) no-repeat scroll 15px 13px transparent;
    background-size: 14px auto;
  }
  .contact-info-page .icon_phone {
    background: url(../images/icon_footer_phone@2x.png) no-repeat scroll 15px 14px transparent;
    background-size: 14px auto;
  }
  .contact-info-page .icon_mail {
    background: url(../images/icon_footer_mail@2x.png) no-repeat scroll 15px 14px transparent;
    background-size: 14px auto;
  }

  .button.get-theme {
    /* background: url(../images/icon-get-theme-black@2x.png) no-repeat scroll 14px center;
      background-size: 17px auto; */
  }
  .button.get-theme:hover {
    background: url(../images/icon-get-theme@2x.png) no-repeat scroll 14px center;
    background-size: 17px auto;
    background-color: #414146;
  }
  .iconed-box:before {
    background-image: url(../images/box-icons@2x.png);
    background-size: 144px auto;
  }
  .styled-list > li:before {
    background-image: url(../images/list-icons@2x.png);
    background-size: 15px 105px;
  }
  .ui-accordion-header span {
    background-image: url(../images/plus@2x.png);
    background-size: 7px auto;
  }
  .ui-accordion-header-active span {
    background-image: url(../images/minus-black@2x.png);
    background-size: 7px auto;
  }
  .ui-accordion-header:hover span {
    background-image: url(../images/plus-black@2x.png);
    background-size: 7px auto;
  }
  .portfolio-pagination .pag-prev {
    background-image: url(../images/prev-small@2x.png);
    background-size: 9px auto;
  }
  .portfolio-pagination .pag-next {
    background-image: url(../images/next-small@2x.png);
    background-size: 9px auto;
  }
  .port-cat-icon {
    background-image: url(../images/next-small@2x.png);
    background-size: 9px auto;
  }
  blockquote:before {
    background-image: url(../images/quote2@2x.png) !important;
    background-size: 19px auto !important;
  }
  .blog-info-container ul li.author {
    background-image: url(../images/author-icon@2x.png);
    background-size: 13px auto;
  }
  .blog-info-container ul li.view {
    background-image: url(../images/view-icon@2x.png);
    background-size: 16px auto;
  }
  .blog-info-container ul li.comment {
    background-image: url(../images/comment-icon@2x.png);
    background-size: 13px auto;
  }
  .blog-info-container ul li.tag {
    background-image: url(../images/tag-icon@2x.png);
    background-size: 13px auto;
  }
  .blog-info-container ul li.post {
    background-image: url(../images/icon-standart-post-black@2x.png);
    background-size: 13px auto;
  }
  .blog-cat-icon {
    background-image: url(../images/next-small@2x.png);
    background-size: 9px auto;
  }
  .pagination-1 .pag-prev {
    background-image: url(../images/prev-small@2x.png);
    background-size: 9px auto;
  }
  .pagination-1 a.pag-prev:hover {
    background-image: url(../images/prev-small-black@2x.png);
    background-size: 9px auto;
  }
  .pagination-1 .pag-next {
    background-image: url(../images/next-small@2x.png);
    background-size: 9px auto;
  }
  .pagination-1 a.pag-next:hover {
    background-image: url(../images/next-small-black@2x.png);
    background-size: 9px auto;
  }
  .nl-form-part-container {
    padding-bottom: 16px;
  }
}
