@font-face {
    font-family: 'OswaldLight';
    src: url('../font/Oswald-Light-webfont.eot');
    src: url('../font/Oswald-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/Oswald-Light-webfont.woff') format('woff'),
         url('../font/Oswald-Light-webfont.ttf') format('truetype'),
         url('../font/Oswald-Light-webfont.svg#OswaldLight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OswaldBook';
    src: url('../font/Oswald-Regular-webfont.eot');
    src: url('../font/Oswald-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/Oswald-Regular-webfont.woff') format('woff'),
         url('../font/Oswald-Regular-webfont.ttf') format('truetype'),
         url('../font/Oswald-Regular-webfont.svg#OswaldBook') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OswaldBold';
    src: url('../font/Oswald-Bold-webfont.eot');
    src: url('../font/Oswald-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/Oswald-Bold-webfont.woff') format('woff'),
         url('../font/Oswald-Bold-webfont.ttf') format('truetype'),
         url('../font/Oswald-Bold-webfont.svg#OswaldBold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OswaldStencilRegular';
    src: url('../font/Oswald-Stencil-webfont.eot');
    src: url('../font/Oswald-Stencil-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/Oswald-Stencil-webfont.woff') format('woff'),
         url('../font/Oswald-Stencil-webfont.ttf') format('truetype'),
         url('../font/Oswald-Stencil-webfont.svg#OswaldStencilRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}

